import React from "react";
import { getLang } from "../../i18n";
import { i18n } from "../../config/i18n";
import Settings from "../../settings";
import _ from "lodash";
import {
    dispatchNewRoute
  } from "../../utils/http_functions";
import "./Home.scss";

const handleKnowMore = (event) => {
  event.preventDefault();
  window.location.href = _.get(Settings, 'links.knowMore', '');
}

const handleContract = (event) => {
    event.preventDefault();
    dispatchNewRoute(i18n.t('common:url.newElectricityContract'));
}

const handleDashboard = (event) => {
    event.preventDefault();
    dispatchNewRoute('/');
}

const HomeCA = () => {
    return (
      <div id="static-home">
          <div className="wrapper">
            <div className="container-bp">
              <header>
                <div className="logo-bp">
                  <img
                    className="logo-img"
                    src="https://www.bonpreuesclat.cat/documents/20182/3865162/bonpreu-esclat-energia.png"
                  />
                </div>
              </header>
              <section>
                <div className="container-bp">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="bg">
                        <h2>
                          {" "}
                          <strong>
                            Si vols saber més sobre BonpreuEsclat Energia
                          </strong>
                          ,<br /> conèixer els avantatges, els productes i les
                          promocions...
                        </h2>
                        <div>
                          <img src="https://www.bonpreuesclat.cat/documents/20182/3865162/m%C3%A9s_info-01.png" />
                          <a href="#" onClick={handleKnowMore} className="btn-mes">
                            Saber-ne més
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="bg">
                        <h2>
                          <strong>
                            Si vols passar-te a BonpreuEsclat Energia, no esperis
                            més!
                          </strong>{" "}
                          <br />
                          Necessitaràs el teu DNI, una factura anterior
                          d’electricitat i el teu número de compte.
                        </h2>
                        <div>
                          <img src="https://www.bonpreuesclat.cat/documents/20182/3865162/contractar-01.png" />
                          <a href="#" onClick={handleContract} className="btn-mes">
                            Contractar
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="bg">
                        <h2>
                          {" "}
                          <strong>Accedeix a la teva àrea de client</strong>
                          <br /> per consultar i gestionar el teu contracte, les
                          factures o els consums.
                        </h2>
                        <div>
                          <img src="https://www.bonpreuesclat.cat/documents/20182/3865162/%C3%A0rea_client-01.png" />
                          <a href="#" onClick={handleDashboard} className="btn-mes">
                            Àrea client
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
      </div>
    );
}

const HomeES = () => {
    return (
    <div id="static-home">
      <div className="wrapper">
          <div className="container-bp">
          <header>
              <div className="logo-bp">
              <img
                  className="logo-img"
                  src="https://www.bonpreuesclat.cat/documents/20182/3865162/bonpreu-esclat-energia.png"
              />
              </div>
          </header>
          <section>
              <div className="container-bp">
              <div className="row">
                  <div className="col-md-4">
                  <div className="bg">
                      <h2>
                      {" "}
                      <strong>
                          Si quieres saber más sobre BonpreuEsclat Energia,
                      </strong>
                      ,<br /> conocer las ventajas, los productos y las promociones...
                      </h2>
                      <div>
                      <img src="https://www.bonpreuesclat.cat/documents/20182/3865162/m%C3%A9s_info-01.png" />
                      <a href="#"  onClick={handleKnowMore} className="btn-mes">
                          Saber más
                      </a>
                      </div>
                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="bg">
                      <h2>
                      <strong>
                          Si quieres pasarte a BonpreuEsclat Energia, ¡no esperes más!
                      </strong>{" "}
                      <br />
                      Necesitarás tu DNI, una factura anterior de electricidad y tu número de cuenta.
                      </h2>
                      <div>
                      <img src="https://www.bonpreuesclat.cat/documents/20182/3865162/contractar-01.png" />
                      <a href="#" onClick={handleContract} className="btn-mes">
                          Contratar
                      </a>
                      </div>
                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="bg">
                      <h2>
                      {" "}
                      <strong>Accede a tu área de cliente</strong>
                      <br /> para consultar y gestionar tu contrato, las facturas o tus consumos.
                      </h2>
                      <div>
                      <img src="https://www.bonpreuesclat.cat/documents/20182/3865162/%C3%A0rea_client-01.png" />
                      <a href="/" onClick={handleDashboard} className="btn-mes">
                          Área cliente
                      </a>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </section>
          </div>
      </div>
    </div>
    );
}

const Home = () => {
  const lang = getLang().locale_code;
  return (
    <div>
        {lang === 'ca-ES' && 
        <HomeCA /> }
        {lang === 'es-ES' &&
        <HomeES /> }
    </div>
  );
}

export default Home;
