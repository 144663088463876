
export const marketingHook = function () {
  console.log('adform lead contact');
  window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
  window._adftrack.push({
      HttpHost: 'track.adform.net',
      pm: 1976969,
      pagename: encodeURIComponent('bonpreu|energia/comparador-potencia/lead/Conversion page'),
      divider: encodeURIComponent('|'),
      order: { 
          itms: [{ 
              step: 3
          }/*,{insert second product here}*/]
      }
  });
  (function(){var s=document.createElement('script');s.type='text/javascript';s.async=true;s.src='https://s2.adform.net/banners/scripts/st/trackpoint-async.js';var x = document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);})();
};
export const statisticsHook = function () {
  // Add custom code to execute
};
